import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { toWidget, viewToModelPositionOutsideModelElement } from '@ckeditor/ckeditor5-widget/src/utils';
import LPFilloutCommand from './lpfilloutcommand';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';

// const EMOTICON = 'i';

export default class LPFilloutEditing extends Plugin {
	static get requires() {
		return [ Widget ];
	}

	init() {
		this._defineSchema();
		this._defineConverters();
		this.editor.commands.add( 'lpfillout', new LPFilloutCommand( this.editor ) );

		this.editor.editing.mapper.on(
			'viewToModelPosition',
			viewToModelPositionOutsideModelElement( this.editor.model, viewElement => viewElement.hasClass( 'lp-fillout' ) )
		);
	}

	_defineSchema() { // ADDED
		const schema = this.editor.model.schema;

		schema.register( 'lpfillout', {
			// Allow wherever text is allowed:
			allowWhere: '$text',

			// The placeholder will act as an inline node:
			isInline: true,

			// The inline widget is self-contained so it cannot be split by the caret and can be selected:
			isObject: true,

			// The placeholder can have many types, like date, name, surname, etc:
			allowAttributes: [ 'filloutid', 'fillouttext' ]
		} );
	}

	_defineConverters() { // ADDED
		const conversion = this.editor.conversion;

		conversion.for( 'upcast' ).elementToElement( {
			view: {
				name: 'lp-fillout'
			},
			model: ( viewElement, modelWriter ) => {
				// Extract the "name" from "{name}".
				const filloutText = viewElement.getChild( 0 ).data.slice();
				const filloutId = viewElement.getAttribute( 'fill-id' );

				return modelWriter.createElement( 'lpfillout', { filloutid: filloutId, fillouttext: filloutText } );
			}
			// model: 'lpformula'
		} );

		conversion.for( 'editingDowncast' ).elementToElement( {
			model: 'lpfillout',
			view: ( modelItem, viewWriter ) => {
				const widgetElement = createFilloutView( modelItem, viewWriter );

				// Enable widget handling on a placeholder element inside the editing view.
				return toWidget( widgetElement, viewWriter );
			}
		} );

		conversion.for( 'dataDowncast' ).elementToElement( {
			model: 'lpfillout',
			view: createFilloutView
		} );

		// Helper method for both downcast converters.
		function createFilloutView( modelItem, viewWriter ) {
			const filloutId = modelItem.getAttribute( 'filloutid' );
			const filloutText = modelItem.getAttribute( 'fillouttext' );
			let lpView = null;

			if ( filloutId ) {
				lpView = viewWriter.createContainerElement( 'lp-fillout', {
					'fill-id': filloutId,
					class: [ 'lp-fillout' ]
				} );
			} else {
				lpView = viewWriter.createContainerElement( 'lp-fillout', {
					class: [ 'lp-fillout' ]
				} );
			}

			// Insert the placeholder name (as a text).
			const innerText = viewWriter.createText( filloutText ) || 'Geen tekst geselecteerd';
			viewWriter.insert( viewWriter.createPositionAt( lpView, 0 ), innerText );

			return lpView;
		}
	}
}
