
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
// import { getFullscreenFromImage } from './utils';

export default class ImageFullscreenEditing extends Plugin {
	/**
	 * @inheritDoc
	 */
	static get pluginName() {
		return 'ImageFullscreenEditing';
	}

	/**
	 * @inheritDoc
	 */
	init() {
		const editor = this.editor;
		// Allow the "linkTarget" attribute in the editor model.
		editor.model.schema.extend( 'image', { allowAttributes: 'imageOnclick' } );

		editor.conversion.for( 'downcast' ).add( dispatcher => {
			dispatcher.on( 'insert:image', ( evt, data, conversionApi ) => {
				const viewWriter = conversionApi.writer;
				const figure = conversionApi.mapper.toViewElement( data.item );
				const img = figure.getChild( 0 );

				if ( img ) {
					viewWriter.setAttribute( 'onclick', 'stroomLightbox(this)', img );
				}
			} );
		} );

		// Tell the editor that <a target="..."></a> converts into the "linkTarget" attribute in the model.
		editor.conversion.for( 'upcast' ).attributeToAttribute( {
			view: {
				name: 'img',
				key: 'onclick'
			},
			model: 'imageOnclick',
			converterPriority: 'low'
		} );
	}
}
