
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import LPFormulaIcon from '../theme/icons/lpformula.svg';
import ContextualBalloon from '@ckeditor/ckeditor5-ui/src/panel/balloon/contextualballoon';
import LPFormulaFormView from './ui/lpformulaformview';
import clickOutsideHandler from '@ckeditor/ckeditor5-ui/src/bindings/clickoutsidehandler';

const LPFORMULA = 'lpformula';

export default class LPFormulaUI extends Plugin {
	init() {
		const editor = this.editor;

		this._balloon = editor.plugins.get( ContextualBalloon );
		// form view
		this.formView = this._createForm();

		// Add button to feature components.
		editor.ui.componentFactory.add( LPFORMULA, locale => {
			// const command = editor.commands.get( EMOTICONS );
			const view = new ButtonView( locale );

			view.set( {
				label: 'Formules',
				icon: LPFormulaIcon,
				// keystroke: 'CTRL+B',
				tooltip: true,
				isToggleable: true
			} );

			// Callback executed once the image is clicked.
			view.on( 'execute', () => {
				// const imageURL = prompt( 'Image URL' );
				this.formView.formulaInputView.inputView.element.value = '';
				this._showPanel();
			} );

			// Bind button to the command.
			view.bind( 'isEnabled' ).to( editor.commands.get( 'lpformula' ), 'isEnabled' );
			view.bind( 'isOn' ).to( editor.commands.get( 'lpformula' ), 'value', value => !!value );

			return view;
		} );

		this._attachActions();
	}

	_createForm() {
		const editor = this.editor;
		const formView = new LPFormulaFormView( editor );

		// Close the panel on esc key press when the form has focus.
		formView.keystrokes.set( 'Esc', ( data, cancel ) => {
			this._hidePanel( true );
			cancel();
		} );

		// Hide the panel after clicking the "Cancel" button.
		this.listenTo( formView, 'lpformulacancel', () => {
			this._hidePanel();
		} );

		this.listenTo( formView, 'lpformulasave', () => {
			const formulaFromView = formView.formulaInputView.inputView.element.value;
			if ( formulaFromView && ( typeof formulaFromView !== 'undefined' ) ) {
				editor.execute( 'lpformula', { value: formulaFromView } );
				this._hidePanel( true );
			} else {
				this._hidePanel();
			}
		} );

		return formView;
	}

	_getBalloonPositionData() {
		const view = this.editor.editing.view;
		const viewDocument = view.document;
		const target =
			view.domConverter.viewRangeToDom( viewDocument.selection.getFirstRange() );

		return { target };
	}

	_showPanel() {
		this._balloon.add( {
			view: this.formView,
			position: this._getBalloonPositionData()
		} );
	}

	_hidePanel( focusEditable ) {
		this.stopListening( this.editor.editing.view, 'render' );

		if ( !this._balloon.hasView( this.formView ) ) {
			return;
		}

		if ( focusEditable ) {
			this.editor.editing.view.focus();
		}

		this.stopListening( this.editor.editing.view, 'render' );
		this._balloon.remove( this.formView );
	}

	_attachActions() {
		// Close the panel on the Esc key press when the editable has focus and the balloon is visible.
		this.editor.keystrokes.set( 'Esc', ( data, cancel ) => {
			if ( this._balloon.visibleView === this.formView ) {
				this._hidePanel();
				cancel();
			}
		} );

		// Close on click outside of balloon panel element.
		clickOutsideHandler( {
			emitter: this.formView,
			activator: () => this._balloon.hasView( this.formView ),
			contextElements: [ this._balloon.view.element ],
			callback: () => this._hidePanel()
		} );
	}
}
