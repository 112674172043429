
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import LPFilloutIcon from '../theme/icons/lpfillout.svg';

export default class LPFilloutUI extends Plugin {
	init() {
		const editor = this.editor;
		// Add button to feature components.
		editor.ui.componentFactory.add( 'lpfillout', locale => {
			// const command = editor.commands.get( EMOTICONS );
			const view = new ButtonView( locale );

			view.set( {
				label: 'Gatentekst',
				icon: LPFilloutIcon,
				tooltip: true,
				isToggleable: true
			} );

			// Callback executed once the image is clicked.
			view.on( 'execute', () => {
				const id = null;
				const text = this.getSelectedText();
				editor.execute( 'lpfillout', { filloutId: id, filloutText: text } );
			} );

			return view;
		} );
	}

	getSelectedText() {
		const editor = this.editor;
		const selection = editor.model.document.selection;
		const range = selection.getFirstRange();
		let txt = '';

		for ( const item of range.getItems() ) {
			if ( item.data ) {
				txt += item.data;
			}
		}
		return txt;
	}
}
