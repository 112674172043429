
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

export default class LpDisableImageDrop extends Plugin {
	/**
	 * @inheritDoc
	 */
	static get pluginName() {
		return 'LpDisableImageDrop';
	}

	/**
	 * @inheritDoc
	 */
	init() {
		const editor = this.editor;
		editor.editing.view.document.on( 'drop', ( evt, data ) => {
			// eslint-disable-next-line no-useless-escape
			const allowedExtensions = /(\jpg|\jpeg|\png|\gif)$/i;
			data.dataTransfer.files.forEach( f => {
				if ( allowedExtensions.test( f.type ) ) {
					// Stop executing next callbacks.
					evt.stop();
					// Prevent the default event action.
					data.preventDefault();
				}
			} );
		}, { priority: 'high' } );
	}
}
