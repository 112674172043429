function initConfig() {
	return [
		{
			name: 'smile',
			text: 'la la-smile-o'
		},
		{
			name: 'meh',
			text: 'la la-meh-o'
		},
		{
			name: 'frown',
			text: 'la la-frown-o'
		},
		{
			name: 'tears',
			text: 'la la-grin-squint-tears'
		},
		{
			name: 'tongue',
			text: 'la la-grin-tongue-squint'
		},
		{
			name: 'dizzy',
			text: 'la la-dizzy'
		},
		{
			name: 'grimace',
			text: 'la la-grimace'
		},
		{
			name: 'kiss',
			text: 'la la-kiss-wink-heart'
		},
		{
			name: 'cry',
			text: 'la la-sad-cry'
		},
		{
			name: 'wink',
			text: 'la la-smile-wink'
		},
		{
			name: 'tired',
			text: 'la la-tired'
		},
		{
			name: 'group',
			text: 'la la-group'
		},
		{
			name: 'heart',
			text: 'la la-heart-o'
		},
		{
			name: 'idea',
			text: 'la la-lightbulb-o'
		},
		{
			name: 'plane',
			text: 'la la-plane'
		},
		{
			name: 'football',
			text: 'la la-soccer-ball-o'
		},
		{
			name: 'peace',
			text: 'la la-hand-peace'
		},
		{
			name: 'handshake',
			text: 'la la-handshake'
		},
		{
			name: 'birthday',
			text: 'la la-birthday-cake'
		},
		{
			name: 'swimmer',
			text: 'la la-swimmer'
		},
		{
			name: 'bed',
			text: 'la la-bed'
		},
		{
			name: 'tv',
			text: 'la la-tv'
		},
		{
			name: 'stroopwafel',
			text: 'la la-stroopwafel'
		},
		{
			name: 'icecream',
			text: 'la la-ice-cream'
		},
		{
			name: 'like',
			text: 'la la-thumbs-o-up'
		},
		{
			name: 'dislike',
			text: 'la la-thumbs-down'
		},
		{
			name: 'bike',
			text: 'la la-bicycle'
		},
		{
			name: 'car',
			text: 'la la-car'
		},
		{
			name: 'clock',
			text: 'la la-clock-o'
		},
		{
			name: 'email',
			text: 'la la-envelope'
		},
		{
			name: 'comment',
			text: 'la la-comment-o'
		},
		{
			name: 'check',
			text: 'la la-check'
		},
		{
			name: 'camera',
			text: 'la la-camera'
		},
		{
			name: 'calendar',
			text: 'la la-calendar'
		},
		{
			name: 'book',
			text: 'la la-book'
		},
		{
			name: 'music',
			text: 'la la-music'
		},
		{
			name: 'phone',
			text: 'la la-phone'
		},
		{
			name: 'gift',
			text: 'la la-gift'
		},
		{
			name: 'home',
			text: 'la la-home'
		},
		{
			name: 'game',
			text: 'la la-gamepad'
		}
	];
}
const config = initConfig();
export { config };
