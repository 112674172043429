import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import LPFilloutUI from './lpfilloutui';
import LPFilloutEditing from './lpfilloutediting';

export default class LPFillout extends Plugin {
	static get requires() {
		return [ LPFilloutUI, LPFilloutEditing ];
	}

	static get pluginName() {
		return 'lpfillout';
	}
}
