import View from '@ckeditor/ckeditor5-ui/src/view';

export default class EmojiButtonView extends View {
	constructor( locale, className ) {
		super( locale );
		this.setTemplate( {
			tag: 'i',
			attributes: {
				class: [
					className
				]
			}
		} );
	}
}
