import Command from '@ckeditor/ckeditor5-core/src/command';

export default class LPFormulaCommand extends Command {
	execute( { value } ) {
		const editor = this.editor;

		editor.model.change( writer => {
			// Create a <placeholder> elment with the "name" attribute...
			const lpformula = writer.createElement( 'lpformula', { formula: value } );

			// ... and insert it into the document.
			editor.model.insertContent( lpformula );

			// Put the selection on the inserted element.
			// writer.setSelection( lpformula, 'on' );
		} );
	}

	refresh() {
		const model = this.editor.model;
		const selection = model.document.selection;

		// const isAllowed = model.schema.checkChild( selection.focus.parent, 'lpformula' );

		// this.isEnabled = isAllowed;
		const isAllowed = model.schema.checkChild( selection.focus.parent, 'lpformula' );
		// const isAllowed = model.schema.checkAttributeInSelection( selection, 'formula' );

		this.isEnabled = isAllowed;
	}
}
