import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import LPFormulaUI from './lpformulaui';
import LPFormulaEditing from './lpformulaediting';

export default class LPFormula extends Plugin {
	static get requires() {
		return [ LPFormulaUI, LPFormulaEditing ];
	}

	static get pluginName() {
		return 'lpformula';
	}
}
