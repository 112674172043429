import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { toWidget, viewToModelPositionOutsideModelElement } from '@ckeditor/ckeditor5-widget/src/utils';
import LPFormulaCommand from './lpformulacommand';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';

// const EMOTICON = 'i';

export default class LPFormulaEditing extends Plugin {
	static get requires() {
		return [ Widget ];
	}

	init() {
		// const editor = this.editor;

		// this._balloon = editor.plugins.get( ContextualBalloon );
		this._defineSchema();
		this._defineConverters();
		this.editor.commands.add( 'lpformula', new LPFormulaCommand( this.editor ) );

		this.editor.editing.mapper.on(
			'viewToModelPosition',
			viewToModelPositionOutsideModelElement( this.editor.model, viewElement => viewElement.hasClass( 'lp-formula' ) )
		);
	}

	_defineSchema() { // ADDED
		const schema = this.editor.model.schema;

		schema.register( 'lpformula', {
			// Allow wherever text is allowed:
			allowWhere: '$text',

			// The placeholder will act as an inline node:
			isInline: true,

			// The inline widget is self-contained so it cannot be split by the caret and can be selected:
			isObject: true,

			// The placeholder can have many types, like date, name, surname, etc:
			allowAttributes: [ 'formula' ]
		} );
	}

	_defineConverters() { // ADDED
		const conversion = this.editor.conversion;

		conversion.for( 'upcast' ).elementToElement( {
			view: {
				name: 'lp-formula',
				classes: [ 'lp-formula' ]
			},
			model: ( viewElement, modelWriter ) => {
				// Extract the "name" from "{name}".
				const formula = viewElement.getChild( 0 ).data.slice();

				return modelWriter.createElement( 'lpformula', { formula } );
			}
			// model: 'lpformula'
		} );

		conversion.for( 'editingDowncast' ).elementToElement( {
			model: 'lpformula',
			view: ( modelItem, viewWriter ) => {
				const widgetElement = createFormulaView( modelItem, viewWriter );

				// Enable widget handling on a placeholder element inside the editing view.
				return toWidget( widgetElement, viewWriter );
			}
		} );

		conversion.for( 'dataDowncast' ).elementToElement( {
			model: 'lpformula',
			view: createFormulaView
		} );

		// Helper method for both downcast converters.
		function createFormulaView( modelItem, viewWriter ) {
			const formula = modelItem.getAttribute( 'formula' );

			const formulaView = viewWriter.createContainerElement( 'lp-formula', {
				class: 'lp-formula'
			} );

			// Insert the placeholder name (as a text).
			const innerText = viewWriter.createText( formula );
			viewWriter.insert( viewWriter.createPositionAt( formulaView, 0 ), innerText );

			return formulaView;
		}
	}
}
